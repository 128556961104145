.modal-email-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-email-content {
    background-color:lightgray;
    padding: 20px;
    border-radius: 0.3rem;
    border-style: double;
    border-color: black;
    border-width: 8px;
    width: 16rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;

    font-size: 0.6rem;
    color: rgb(50,50,50);
  }

  .modal-email-label {
    padding-right: 0.5rem;
  }

  .modal-email-input {
    font-family: "Orbitron";
    font-size: 0.6rem;
    border-radius: 0.2rem;
    padding-left: 0.1rem;
    border: none;
  }

  .modal-email-content>hr {
    border-style: dashed;
    border-width: 2px;
    margin-bottom: 1rem;
  }
  
  .modal-email-btn {
    font:inherit;
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: 1rem;
    padding: 0.3rem;
    width: 5rem;
    border-width: 0;
    border-radius: 0.2rem;
    outline-width: 5px;
    --background-color: rgb(255, 204, 102);
    --background-color-hover:  rgb(253, 218, 147);
  }


  .modal-email-closebtn {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
  }