.article{
    position: fixed;
    max-width: 6rem;
    outline-width: 3px;
    outline-color: black;
    outline-style: solid;
    background-color: #ffffff;
    color: black;
    padding: 0.8em;
    font-family: "Source Sans 3";
    border-radius: 3px;
    word-wrap: break-word; /* Break long words onto the next line */
    white-space: normal;
    font-size: 0.8rem;
}
.canvas {
    position: fixed;
}