.btn-train-gobacktomenu {
    float: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    width: 2rem;
    height: 2rem;
    background-color: transparent;
    border-radius: 0.3rem;
    border-width: 3px;
    border-style: solid;
    transition: background-color 0.2s;
}

.btn-train-gobacktomenu > .icon-goback {
    width: 1.3rem;
}

.btn-train-gobacktomenu:hover{
    background-color: rgba(255,255,255,0.5);
}