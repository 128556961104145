.label-score {
    font-size: 2.5rem;
    font-weight: 600;
}

.container-gamescore{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 0.5rem;
}

#label-result {
    font-size: 5rem; 
    font-weight: 600;
}
@keyframes shake{
    0% {
        transform: translate(-50%, -50%)
    }
    25% {
        transform: translate(-50%, -49%) rotate(4deg)
    }
    50% {
        transform: translate(-50%, -50%) 
    }   
    75% {
        transform: translate(-50%, -49%) rotate(-4deg)
    }
    100% {
        transform: translate(-50%, -50%) 
    }
}

@keyframes arise {
    from {transform:translate(-50%, -50%) scale(0.1); opacity: 0;}
    to {transform:translate(-50%, -50%); opacity: 1; }
}

#container-gameend {
    position:absolute;
    display : flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    gap: 0.5rem;
    animation: arise 0.3s;
    animation-timing-function: linear;
}
#label-resultscoretext{
    font-size : 0.7rem;
    color: black;
}

#button-gobacktopreview {
    --background-color: rgb(255, 187, 51);
    --background-color-hover: rgb(255, 204, 102);
    color: white;
    font-family: inherit;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    font-weight: 600;
    text-align: center;
    border-radius: 0.3rem;
    border-width: 0;
    outline-width: 5px;
    padding : 0.5rem;
    width: 8rem;
    margin: 0.5rem;
  
}

.container-oprankinfo{
    background-color: rgba(0,0,0,0.3);
    display: flex;
    position: absolute;
    left: 100%;
    padding: 0.5rem;
    transform: translateX(-100%);
    border-width: 10px;
    border-bottom-left-radius: 0.5rem;
    gap: 0.2rem;
    padding-right: 1rem;
}
.container-oprankinfo > label {
    font-size: 2.3rem;
    font-weight: 900;
}
.container-oprankinfo > article {
    font-weight: 700;
    font-size: 0.7rem;
    color: black;
}
.container-oprankinfo > article > span{
    font-size: 0.5rem;
    font-weight: 300;
    color: white;
}

.container-myrankinfo{
    background-color: rgba(0,0,0,0.3);
    display: flex;
    position: absolute;
    left:0%;
    padding: 0.5rem;
    border-width: 10px;
    border-bottom-right-radius: 0.5rem;
    gap: 0.2rem;
    padding-left: 1rem;
}
.container-myrankinfo > label {
    font-size: 2.3rem;
    font-weight: 900;
}
.container-myrankinfo > article {
    font-weight: 700;
    font-size: 0.7rem;
    color: black;
}
.container-myrankinfo > article > span {
    font-size: 0.5rem;
    font-weight: 300;
    color: white;
}

.container-rankinfotvt {
    flex-direction: column;
    align-items: start;
    gap: 0.4rem;
}

.container-rankinfotvt > article {
    font-size: 1rem;
}

.container-startanim {
    position:fixed;
    top:50%;

    height: 30%;
    width: 50%;
    display: flex;
    gap:0.5rem;
    background-color: rgba(0,0,0,0.3);

    border-top-style: double;
    border-top-width: 0.4rem;
    border-bottom-style: double;
    border-bottom-width: 0.4rem;
}

.container-startanimleft {
    left: 0;
    align-items: center;
    justify-content: center;
    transform: translate(0%, -50%);
    animation: slidefromleft 0.3s linear;
}

.container-startanimright {
    left: 50%;
    align-items: center;
    justify-content: center;
    transform: translate(0%, -50%);
    animation: slidefromright 0.3s linear;
}

.span-startanimname {
    font-size: 1.5rem;
    font-weight: 600;
}
.span-startanimrating {
    font-size: 0.8rem;
    color: black;
    transform: translateY(0.5rem);
}
.span-verses{
    position: fixed;
    display: flex;
    font-size: 3.5rem;
    font-weight: 600;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: showUp 0.9s;
}
.container-startanimtwovtwo > span{
    font-size: 1.2rem;
}


@keyframes showUp {
    0% {
        transform: translate(-50%, -50%) scale(0) ;
    }
    85% {
        transform: translate(-50%, -50%) scale(0) ;
    }
    100% {
        transform: translate(-50%, -50%) scale(1) ;
    }
}


@keyframes slidefromright{
    from{
        transform: translate(100%, -50%);
    }
    to {
        transform: translate(0, -50%);
    }
}

@keyframes slidefromleft{
    from{
        transform: translate(-100%, -50%);
    }
    to {
        transform: translate(0, -50%);
    }
}