
@keyframes from-ontopsignup{
    from {transform: translate(-50%, -100vh)}
    to {top: translate(-50%, -50%)}
  }

#container-signup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background: rgba(0,0,0,0.5);
    padding: 1rem;
    border-style: double;
    border-width: 0.3rem;
    border-color: white;
    border-radius: 0.5rem;
    animation: from-ontopsignup 0.5s;
}

#label-signup{
    font-weight: 600;
    font-size: 1.3rem;
}

#hr-signup {
    margin: 0.3rem;
    border-style:dashed;
    border-width: 2px;
}

.text-signup {
    font-family: inherit;
    font-size:  0.8rem;
    font-weight: 600;
}

.label-signup {
    width: 3rem;
    font-weight: 400;
    text-align: end;
    width: 100%;
}

.input-signup {
    border-style: none;
    border-radius: 0.3rem;
    padding-left: 0.2rem;
}

.btn-signup {
    width: 6rem;
    margin-top: 0.5rem;
    padding: 0.3rem;
    border-radius: 0.3rem;
    border-width: 0;
    outline-width: 4px;
}

#form-signup{
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: 1rem;
    row-gap: 0.5rem;
}

#container-signupbtn{
    grid-column: span 2;
    display: flex;
    position: relative;
    margin-top: 1rem;
    left: 50%;
    align-items: center;
    width: 15rem;
    justify-content: space-around;
    transform: translateX(-50%);
}

#btn-signup {
    position: relative;
    --background-color: rgb(255, 187, 51);
    --background-color-hover:  rgb(255, 204, 102);
    color: white;
}

#btn-goback {
    --background-color: gainsboro;
    --background-color-hover: rgb(167, 167, 167);
}

#article-signupinfo {
    text-align: center;
    font:inherit;
    font-size: 0.5rem;
    margin-top: 0.5rem;
}