/*공통적인 css만 작성함. (ex) font, 윈도우 크기 등*/

:root{
  font-size: 5vh;
}

.App {
  position: absolute;
  text-align: center;
  min-height: 100vh;
  width: 100vw;
  color: white;
  background-color: gainsboro ;
  z-index: -2;
}

button{
  cursor:pointer;
}

.text-border {
  --border-color : rgb(0,0,0,0.5);
  text-shadow: 2px 0 var(--border-color), -2px 0 var(--border-color), 0 2px var(--border-color), 0 -2px var(--border-color),
             1px 1px var(--border-color), -1px -1px var(--border-color), 1px -1px var(--border-color), -1px 1px var(--border-color);
}

#canvas-main {
  position:absolute;
  left: 0%;
  top:0%;
  width:100%;
  height:100%;
  background-color: rgb(196, 227, 227);
  z-index: -1;
}

.button-coloroutlinechanging {
  background-color: var(--background-color);
  outline-style: solid;
  outline-color: transparent;
  transition: background-color 0.2s ease, color 0.2s ease, outline-color 0.1s linear;
}
.button-coloroutlinechanging:hover {
  background-color: var(--background-color-hover);
  outline-color: white;
}