#div-shade{
  position: absolute;
  left: 0;
  top:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  animation: color-fadein 1s;
}

@keyframes color-fadein{
  from {background-color: transparent}
  to {background-color: rgba(0, 0, 0, 0.3)}
}

@keyframes from-ontop{
  from {transform: translate(-50%, -100vh)}
  to {top: translateX(-50%)}
}

#container-logoandui{
    display:flex;
    flex-direction: column;
    position:absolute;
    left: 50%;
    top: 1rem;
    transform: translateX(-50%);
    animation: from-ontop 0.5s;
    align-items: center;
  }
  
  #img-logo{
    width : 24rem;
    height: 6rem;
  }
  
  #container-ui{
    display: flex;
    column-gap: 1rem;
  }
  
  #container-rankinfo{
    min-width: 8rem;
    border-width: 10px;
    border-radius: 7px;
    border-style:double;
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  #label-ranking{
    letter-spacing: 0.1rem;
    font-size: 1rem;
    font-weight: 500;
  }
  
  #hr-ranking{
    margin: 5px;
    border-style:dashed;
    border-width: 2px;
    
  }
  
  #label-rankingletter{
    font-size: 3rem;
    font-weight:900;
  }
  
  #article-info{
    position:relative;
    font-size: 0.5rem;
    text-align:center;
    color: rgb(0,0,0);
    font-weight:800;
  }
  
  #container-gameselectbuttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  #button-gameselection-start {
    --color: rgb(255,255,255,0.9);
    --background-color: #7ac3ff;
    --background-color-hover: rgb(187, 230, 255);
  }
  
  #button-gameselection-train {
    --color:rgb(255,255,255,0.9);
    --background-color: rgb(255, 137, 122);
    --background-color-hover: rgb(247, 184, 175);
  }
  #button-gameselection-twovtwo {
    --color:rgb(255,255,255,0.9);
    --background-color: #0067bb;
    --background-color-hover: #4a89bd;;
  }
  
  .button-gameselection {
    position: relative;
    padding : 0.6rem;
    width: 8rem;
    margin: 0.5rem;

  
    font-family: inherit;
    font-size: 5vh;
    letter-spacing: 0.1rem;
    font-weight: 600;
    text-align: start;
  
    color: var(--color);
    border-radius: 0.3rem;
    border-width: 0;
  
    outline-width: 5px;
  }
  
  .button-gameselection > .icon {
    fill : rgb(0,0,0,0.6);
    width : 1.4rem;
    height: 1.4rem;
    position: absolute;
    top: calc(50% - 0.7rem);
    left: 0.7rem;
    transition: transform 0.3s;
  }
  
  .button-gameselection:hover > .icon{
    transform: scale(1.05, 1.05) translateX(-0.2rem);
  }
  
  .button-gameselection > label {
    position: relative;
    left: 1.6rem;
    top: 0.1rem;
    cursor:inherit;
  }
  
  #container-settings {
    display: flex;
    flex-direction: column;
    position: absolute;
    top:0%;
    left: 100%;
    height: 100%;
    transform: translateX(-100%);
  
    background-color: rgb(0,0,0,0.5);
    
    transition: width 0.3s;
    align-items: start;
  }

  .container-settings-clicked {
    width: 7rem;
  }
  /*
  #button-settings > .icon {
    --border-color : black;
    fill: rgb(255, 255, 255);
    width: 1.2rem;
    height: 1.2rem;
    stroke: black;
    stroke-width: 2%;
  }

  #button-settings {  
    font:inherit;
  
    background-color: transparent;
    border-radius: 0.2rem;
    border-width: 0;
  }
  
  @keyframes rotate-1turn {
    from {transform: rotate(0);}
    to {transform: rotate(1turn);}
  }
  
  #button-settings:hover > .icon{
    animation: rotate-1turn 3s infinite linear;
  }*/

  #hr-settings {
    color: white;
    width: 80%;
    border-top-style: solid;
    border-top-width: 2px;
  }
  
  #container-navbar {
    position: relative;;
    float: right;
    top: 0;
    display: flex;
    padding: 0.5rem;
    width: 6rem;
    justify-content:space-around;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    background-color: rgba(0,0,0,0.2);
 }

 #container-navbar > button {
  width: 1.7rem;
  height: 1.7rem;
  padding: 0.3rem;
  border-radius: 0.85rem;

  border: solid;
  border-color: var(--border-color);
  border-width: 3px;
  
  background-color:transparent;
  transition: background-color 0.2s;
 }

 #container-navbar > button:hover {
  background-color: var(--border-color);
 }

 #path-leaderboard {
  fill: white;
  stroke: none;
 }
 
 #path-notice {
  fill: white;
  stroke: none;
 }

 #path-settings {
  fill: white;
  stroke: none;
 }

 #button-leaderboard {
  --border-color: #ffd633;
 }

 #button-notice {
  --border-color: #ff6666;
 }
#button-settings {
  --border-color:  #202060; 
}

#span-emailinfo {
  font-size: 0.5rem;
  text-align:center;
  color: rgb(0,0,0);
  font-weight:800;
}


@keyframes clickme{
  from {
    color: rgb(145, 255, 0);
  }
  to {
    color : black;
  }
}

.emailinfowarning {
  font-style: italic;
  font-weight: 300;
  animation: clickme 1s ease-in-out 0ms infinite alternate-reverse;
  cursor: pointer;
}

.chatcomp-preview {
  height: 10rem;
}

.article-summary {
    position: fixed;
    left: 0;
    top: 0;
    text-align: start;
    font-family:"Source Sans 3";
    font-size: 0.55rem;
    padding: 0.5rem;
    color:rgb(255, 247, 0);
}

@keyframes server-select-slidein{
  from {transform: translateX(-40%);}
  to {transform: translateX(0);}
}

.container-server-select{
  color: white;
  display: flex;
  align-items: center;
  height: 1rem;
  font-size: 0.55rem;
  font-weight: 700;
  justify-content:center;
  top: 50%;
  gap: 0.5rem;
  /*background: linear-gradient(to right, #bea937,  #fff3ae);*/
  background-color:  #85cdc4;
  border-radius: 0 0.5rem 0.5rem 0;
  padding: 0.2rem;
  animation: server-select-slidein 0.3s;
}

.container-leftbottomflexbox {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20%;
  height: 60%;
  flex-direction: column;
  align-items:center;
  justify-content: space-between;
}

.container-server-select > .server-select {
  font-family:"Source Sans 3";
  font-size: 1em;
  border-radius: 0.2rem;
}