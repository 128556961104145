.modal-notice-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-notice-content {
    background-color:lightgray;
    padding: 20px;
    border-radius: 0.3rem;
    border-style: double;
    border-color: black;
    border-width: 8px;
    width: 16rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    text-align: start;

    font-size: 0.6rem;
    font-weight: 400;
    color: rgb(50,50,50);
  }

  .modal-notice-content > h1 {
    text-align: center;
  }

  .modal-notice-content>hr {
    border-style: dashed;
    border-width: 2px;
    margin-bottom: 1rem;
  }

  .modal-notice-closebtn {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
  }